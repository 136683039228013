var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "c-container",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ]
    },
    [
      _c(
        "c-box",
        {
          attrs: { slot: "header", flex: "row", justify: "space-between" },
          slot: "header"
        },
        [
          _c(
            "div",
            { staticStyle: { width: "60%", margin: "20px 10%" } },
            [
              _c(
                "el-steps",
                { attrs: { active: 2, "align-center": "" } },
                [
                  _c("el-step", {
                    attrs: { title: "步骤 1", description: "填写基本信息" }
                  }),
                  _c("el-step", {
                    attrs: { title: "步骤 2", description: "填写样本信息" }
                  }),
                  _c("el-step", {
                    attrs: { title: "步骤 3", description: "完成" }
                  })
                ],
                1
              )
            ],
            1
          )
        ]
      ),
      _vm.deliveryType === 0
        ? _c(
            "div",
            [_c("Tissue", { attrs: { id: _vm.id, height: _vm.height } })],
            1
          )
        : _vm._e(),
      _vm.deliveryType === 1
        ? _c(
            "div",
            [_c("NucleicAcid", { attrs: { id: _vm.id, height: _vm.height } })],
            1
          )
        : _vm._e(),
      _vm.deliveryType === 2
        ? _c(
            "div",
            [_c("Library", { attrs: { id: _vm.id, height: _vm.height } })],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }