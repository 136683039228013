import "core-js/modules/es.number.constructor"; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import pageMixin from '@/plugins/mixins/pageMixin';
import cDialigMixin from '@/plugins/mixins/cDialigMixin';
import Tissue from '../components/tissue/tissue.vue';
import NucleicAcid from '../components/nucleicAcid/nucleicAcid.vue';
import Library from '../components/library/library.vue';

export default {
  mixins: [cDialigMixin, pageMixin],
  components: {
    Tissue: Tissue,
    NucleicAcid: NucleicAcid,
    Library: Library },

  data: function data() {
    return {
      width: '1100px',
      deliveryType: '', //送检类型
      id: '',
      height: 'calc(100vh - 410px)' //表格高度
    };
  },
  mounted: function mounted() {
    this.id = this.$route.query.id;
    this.deliveryType = Number(this.$route.query.deliveryType);
  },
  methods: {} };